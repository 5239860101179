/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useCallback } from "react";
import style from "../styles/shop.module.scss";
import Banner from "../components/shared/Banner";
import ProductCard from "../components/shared/ProductCard";
import ReactPaginate from "react-paginate";
import Loader from "../components/shared/Loader";
import { GlobalContext } from "../context/GlobalContext";
import withHelmet from "../seo/withHelmet";
import { shopHelmetProps } from "../seo/helmetProps";
import { debounce } from "lodash";

const Shop = () => {
  const {
    getProducts,
    products,
    loading,
    pagination,
    getCategories,
    categories,
  } = useContext(GlobalContext);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedSubctegory, setSelectedSubctegory] = useState(null);
  const [otherFilter, setOtherFilter] = useState(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    getProducts(1, 8);
    getCategories();
  }, []);

  useEffect(() => {
    if (pagination) {
      setTotalPages(pagination.pageCount);
    }
  }, [pagination]);

  const handleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
    }, 500),
    []
  );
  useEffect(() => {
    getProducts(
      page,
      8,
      selectedSubctegory === "Kategoria" || selectedSubctegory === "Të Gjitha"
        ? null
        : selectedSubctegory,
      otherFilter === "price:asc" ||
        otherFilter === "price:desc" ||
        otherFilter === "createdAt:desc"
        ? otherFilter
        : null,
      otherFilter === "discount" ? true : null,
      otherFilter === "top" ? true : null,
      search
    );
    setPage(page);
  }, [page, selectedSubctegory, otherFilter, search]);

  return (
    <div className={style.wrapper}>
      <Banner
        title={"Shop"}
        current={"Shop"}
        from={[{ name: "Ballina", link: "/" }]}
      />

      <div className={style.filters}>
        <input
          type="text"
          placeholder="Kërko..."
          onChange={(e) => handleSearch(e.target.value)}
        />
        {categories.length === 0 || categories === undefined ? (
          <></>
        ) : (
          <div className={style.selects}>
            <select
              defaultValue={selectedSubctegory}
              key={70}
              onChange={(e) => {
                setPage(1);

                setSelectedSubctegory(e.target.value);
              }}
            >
              <option value="Kategoria" disabled>
                Kategoria
              </option>
              <option value={null} key={111}>
                Të Gjitha
              </option>
              {categories.map((category) => (
                <React.Fragment key={category.id}>
                  <option disabled>{category.name}</option>

                  {category.subcategories.map((sub) => (
                    <option key={sub.id} value={sub.documentId}>
                      {sub.name}
                    </option>
                  ))}
                </React.Fragment>
              ))}
            </select>
            <select
              defaultValue={otherFilter}
              key={55}
              onChange={(e) => {
                setPage(1);
                setOtherFilter(e.target.value);
              }}
            >
              <option key={0} value={null}>
                Sipas Relevancës
              </option>
              <option key={1} value={"price:asc"}>
                Çmimi më i ulët
              </option>
              <option key={2} value={"price:desc"}>
                Çmimi më i lartë
              </option>
              <option key={3} value={"discount"}>
                Me Zbritje
              </option>
              <option key={4} value={"createdAt:desc"}>
                Më të rejat
              </option>
              <option key={5} value={"top"}>
                Më të shiturat
              </option>
            </select>
          </div>
        )}
      </div>

      {loading || products === undefined || pagination === undefined ? (
        <Loader />
      ) : (
        <>
          {products.length === 0 ? (
            <h1>Nuk ka produkte</h1>
          ) : (
            <div className={style.products}>
              {products?.map((product) => {
                return <ProductCard product={product} key={product?.id} />;
              })}
            </div>
          )}
        </>
      )}
      <div className={style.paginationWrapper}>
        <ReactPaginate
          containerClassName={style.pagination}
          pageClassName={style.pageItem}
          activeClassName={style.active}
          onPageChange={(event) => {
            setPage(event.selected + 1);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          pageCount={totalPages}
          breakLabel="..."
          previousLabel={false}
          nextLabel={false}
        />
      </div>
    </div>
  );
};

export default withHelmet(Shop, shopHelmetProps);
