import React from "react";
import style from "../../styles/buynow.module.scss";
import { useNavigate } from "react-router-dom";
import { useCart } from "react-use-cart";
import ReactPixel from "react-facebook-pixel";
import { RiMoneyEuroCircleLine } from "react-icons/ri";

const BuyNow = ({ product, quantity }) => {
  const { addItem } = useCart();
  const navigate = useNavigate();
  const addToCart = (product) => {
    addItem(product, quantity);
    navigate("/cart");
    ReactPixel.track("AddToCart", {
      content_ids: [product.documentId],
      content_name: product.Title,
      content_type: "product",
      value: product.price,
      currency: "EUR",
    });
  };

  return (
    <button
      className={style.button}
      onClick={() => {
        addToCart(product);
      }}
    >
      <RiMoneyEuroCircleLine size={30} />
      Blej Tani
    </button>
  );
};

export default BuyNow;
