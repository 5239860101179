import React from "react";
import style from "../../styles/addtocart.module.scss";
import { useCart } from "react-use-cart";
import { toast } from "react-toastify";
import ReactPixel from "react-facebook-pixel";
import { IoCartOutline } from "react-icons/io5";

const AddToCart = ({ product, quantity }) => {
  const { addItem } = useCart();

  return (
    <button
      className={style.button}
      onClick={() => {
        addItem(product, quantity);
        toast.success("Produkti u shtua në shportë");
        ReactPixel.track("AddToCart", {
          content_ids: [product.documentId],
          content_name: product.Title,
          content_type: "product",
          value: product.price,
          currency: "EUR",
        });
      }}
    >
      <IoCartOutline size={30} />
      Shto në shportë
    </button>
  );
};

export default AddToCart;
