/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import style from "../styles/productDetail.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import ReactMarkdown from "react-markdown";
import Quantity from "../components/shared/Quantity";
import AddToCart from "../components/shared/AddToCart";
import BuyNow from "../components/shared/BuyNow";
import ProductsSlider from "../components/shared/ProductsSlider";
import { GlobalContext } from "../context/GlobalContext";
import { useParams } from "react-router-dom";
import Loader from "../components/shared/Loader";
import withHelmet from "../seo/withHelmet";
import { singleProductHelmetProps } from "../seo/helmetProps";
import { Helmet } from "react-helmet";
import ReactPixel from "react-facebook-pixel";

const ProductDetail = () => {
  const { id } = useParams();

  const { product, getProduct, loading, getRelatedPorducts, relatedProducts } =
    useContext(GlobalContext);
  const [quantity, setQuantity] = useState(1);

  const addQuantityField = (quantity) => {
    setQuantity(quantity);
  };

  useEffect(() => {
    getProduct(id);
    if (id) {
      ReactPixel.track("ViewContent", {
        content_ids: [id],
        content_type: "product",
      });
    }
  }, [id]);

  useEffect(() => {
    getRelatedPorducts(product?.id, product?.subcategory?.documentId);
  }, [product]);
  const singleProductHelmetProps = {
    meta: [
      {
        name: "keywords",
        content:
          "skincare product, premium cream, serum, lotion, body milk, massage oil, beauty product, produkt kujdesi lëkure, krem premium, serum, locion, qumësht trupi, vaj për masazh, produkt bukurie",
      },
    ],
    link: [
      {
        rel: "canonical",
        href: `https://www.onbeautyaesthetics.com/product/${id}`,
      },
    ],
  };

  return (
    <>
      {product === undefined || loading ? (
        <Loader />
      ) : (
        <>
          <Helmet>
            {product.Title && <title>{product.Title}</title>}

            {singleProductHelmetProps.meta.map((metaProps, index) => (
              <meta key={`meta-${index}`} {...metaProps} />
            ))}
            <meta
              key={`meta-2`}
              name="description"
              content={product?.Description}
            />

            {singleProductHelmetProps.link.map((linkProps, index) => (
              <link key={`link-${index}`} {...linkProps} />
            ))}
          </Helmet>
          <div className={style.wrapper}>
            <div className={style.innerContainer}>
              <div className={style.product}>
                <Swiper
                  direction={"horizontal"}
                  slidesPerView={1}
                  navigation={true}
                  spaceBetween={30}
                  loop
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Navigation]}
                  className={style.productsImages}
                >
                  {product?.photos.map((photo) => {
                    return (
                      <SwiperSlide
                        className={style.photos}
                        style={{
                          backgroundImage: `url(https://cms.onbeautyaesthetics.com${photo.url})`,
                        }}
                        key={photo.url}
                      ></SwiperSlide>
                    );
                  })}
                </Swiper>

                <div className={style.productData}>
                  <h1>{product?.Title}</h1>
                  <div className={product?.price}>
                    <div className={style.priceWithoutDiscount}>
                      <p>
                        {" "}
                        Çmimi:{" "}
                        <span
                          style={
                            product?.discount > 0
                              ? { textDecoration: "line-through" }
                              : {}
                          }
                        >
                          {" "}
                          {product?.price.toFixed(2)}€
                        </span>
                      </p>
                    </div>
                    {product?.discount > 0 ? (
                      <p>
                        Çmimi me zbritje:{" "}
                        {(
                          product?.price -
                          (product?.price * product?.discount) / 100
                        ).toFixed(2)}
                        €
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className={style.description}>
                    <ReactMarkdown className={style.markDown}>
                      {product?.Description}
                    </ReactMarkdown>
                    <p className={style.origin}>
                      Origjina: <strong>{product?.madeIn}</strong>
                    </p>
                  </div>
                  <Quantity
                    onQuantityChange={addQuantityField}
                    itemId={product?.id}
                    isCart={false}
                  />

                  <div className={style.total}>
                    <h4>Total:</h4>{" "}
                    <h4>
                      {product?.discount > 0
                        ? (
                            product?.price -
                            ((product?.price * product?.discount) / 100) *
                              quantity
                          ).toFixed(2)
                        : (product?.price * quantity).toFixed(2)}
                      €{" "}
                    </h4>
                  </div>
                  <div className={style.buttons}>
                    <AddToCart product={product} quantity={quantity} />
                    <BuyNow product={product} quantity={quantity} />
                  </div>
                </div>
              </div>
              <div className={style.relatedPorducts}>
                {relatedProducts === undefined ||
                relatedProducts?.length === 0 ||
                loading ? (
                  <></>
                ) : (
                  <>
                    <h1 className={style.title}>Produkte të ngjashme</h1>
                    <ProductsSlider relatedProducts={relatedProducts} />
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default withHelmet(ProductDetail, singleProductHelmetProps);
